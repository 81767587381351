@font-face {
  font-family: GalanoGrotesque;
  src: url('./fonts/GalanoGrotesque-Medium.woff2');
  font-style: medium;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: GalanoGrotesque;
  src: url('./fonts/GalanoGrotesque-SemiBold.woff2');
  font-style: medium;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url('./fonts/Graphik-Regular.woff2');
  font-style: regular;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Graphik;
  src: url('./fonts/Graphik-Medium.woff2');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: Graphik, GalanoGrotesque, system-ui, sans-serif;
  font-size: 1.6rem;
  line-height: normal;
  min-height: 100vh;
  width: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: GalanoGrotesque;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
