@value colorSmoke, colorGrey, colorPrimary from '~src/style/shared.module.css';

.contactMeForm > form {
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 24px;
}

.inputWrapper,
.phoneNumberInputWrapper {
  position: relative;

  padding: 16px;

  border: 1px solid colorSmoke;
  border-radius: 24px;
}

.inputWrapper > input,
.inputWrapper > textarea,
.phoneNumberInputWrapper > input {
  border: none;
  outline: none;

  width: 100%;
}

.phoneNumberPrefix {
  position: absolute;

  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  height: 100%;

  padding-right: 8px;

  color: colorGrey;

  display: flex;
  align-items: center;

  border-right: 1px solid colorSmoke;
}

.inputWrapper > textarea {
  resize: vertical;
}

.inputWrapper > .label,
.phoneNumberInputWrapper > .label {
  color: colorGrey;

  position: absolute;

  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  transition: all 0.2s;

  pointer-events: none;
}

.phoneNumberInputWrapper > input {
  padding-left: 48px;
}

.phoneNumberInputWrapper > .label {
  left: 64px;
}

.inputWrapper:focus-within > .label,
.phoneNumberInputWrapper:focus-within > .label,
.inputWrapper > input:not(:placeholder-shown) + .label,
.phoneNumberInputWrapper > input:not(:placeholder-shown) + .label,
.inputWrapper > textarea:not(:placeholder-shown) + .label {
  top: 12px;

  font-size: 8px;
}

.red {
  color: red;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row;

  margin-left: 16px;
  margin-right: 16px;

  gap: 8px;
}

.formError {
  position: absolute;

  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 12px;

  color: red;
}

.button {
  position: relative;

  color: rgba(255, 255, 255, 1);
  background-color: colorPrimary;

  border: none;
  border-radius: 32px;

  text-align: center;
  align-self: stretch;

  cursor: pointer;
  padding: 12px 31px;
}

.button:hover {
  transition: all 0.2s;

  transform: scale(1.025);
  filter: brightness(1.2);
}

.disabledButton {
  background-color: colorSmoke;
}

.disabledButton:hover {
  transform: none;
  filter: none;

  cursor: inherit;
}

.submitConfirmationWrapper {
  margin-left: 16px;
  margin-right: 16px;
}
