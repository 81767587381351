@value colorSmoke from '~src/style/shared.module.css';

.autoSuggest {
  position: relative;
  width: min(100%, 700px);
}

.autoSuggest input {
  width: 100%;
  border-radius: 24px;
  border: none;
  padding: 16px;
  outline: none;
  transition: all 0.1s;
}

.autoSuggest input:focus {
  outline: none;
}

.autoSuggest .suggestionsShown {
  border-radius: 24px 24px 0px 0px;
  border-bottom: none;
}

.autoSuggest ul {
  position: absolute;
  top: 100%;
  left: 0;

  display: grid;
  background-color: white;

  width: 100%;

  list-style-type: none;

  border-radius: 0px 0px 24px 24px;
  border: 2px solid colorSmoke;
  border-top: none;
  overflow: hidden;
}

.autoSuggest li {
  cursor: pointer;
  padding: 12px;
}

.autoSuggest li:hover,
.autoSuggest li:focus {
  background-color: colorSmoke;
}

.withIcon input {
  padding-left: 44px;
}

.active {
  background-color: colorSmoke;
}

.iconContainer {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
}
